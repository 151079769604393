$background-color: var(--primary-background-color, #e9e1f1);
$background-color-secondary: var(--secondary-background-color, #faf6ff);
$primary-color: var(--primary-front-color, #2B3C90);

$faded-background: var(--faded-background-color, #dad2e7);
$faded-line: var(--faded-line-color, #d2c5df);

$primary-button-color: var(--primary-button-color, #51338B);
$primary-light-color: var(--primary-light-color, #ac98c5);
$primary-text-color: var(--primary-text-color, #4b4e52);

$category-color-active: var(--category-color, #2B3C90);
$category-color-finished:  #8F67A4;

$text-small:1rem;
$text-regular:1.7rem;
$text-large:2rem;
$text-xlarge: 3rem;
$text-xxlarge: 5rem;

$gap-small:1rem;
$gap-regular:1.6rem;
$gap-large:2rem;
$gap-xlarge: 3rem;
$gap-xxlarge: 6rem;

$content-max-width: auto;

$mq-desktop: 1024px;
$mq-tablet: 768px;
$mq-mobile: 480px;