.summary-wrapper {
    grid-area: main;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: $gap-xlarge $gap-large;
    @media (min-width: $mq-tablet) {
        // padding:calc($gap-xlarge * 2) calc($gap-xlarge * 4);
    }

    .summary-content-wrapper {
        padding-top: $gap-xxlarge;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        display: grid;
        width: 100%;
        max-width: $content-max-width;
        gap: ($gap-xlarge * 2);

        .question-header-wrapper {
            text-align: center;
        }
        h1 {
            font-size: clamp($text-xlarge, 6vw, $text-xxlarge);
            line-height: clamp(calc($text-xlarge + 0.5rem), 6vw, calc($text-xxlarge + 0.5rem));
            color: $primary-color;
            font-family: "text-regular";
            margin: 0 auto;
            margin-bottom: $gap-large;
            font-weight: 400;
        }
        h2 {
            font-size: clamp($text-large, 5vw, $text-xlarge);
            line-height: clamp(calc($text-xlarge + 0.5rem), 6vw, calc($text-xxlarge + 0.5rem));
            color: $primary-color;
            font-family: "text-regular";
            font-weight: 400;
        }
        h3 {
            font-size: $text-regular;
            line-height: $text-regular;
            color: $primary-color;
            font-family: "text-bold";
        }
        p {
            font-family: "text-regular";
            font-weight: 400;
            font-size: $text-regular;
            line-height: calc($text-regular * 1.55);
            margin-bottom: $gap-xlarge;
        }
        ul {
            margin-top: $gap-regular;
            list-style-position: outside;
            padding-left: 18px;
            li {
                margin-bottom: $gap-regular;
            }
        }
        .summary-info {
            display: flex;
            gap: $gap-large;
            flex-direction: column;
            .summary-info-content {
                display: grid;
                grid-template-columns: 1fr;
                grid-gap: calc($gap-xlarge * 1.2);
                @media (min-width: $mq-tablet) {
                    grid-template-columns: 2fr 3fr;
                }
            }
        }
        .download-wrapper {
            background-color: $background-color-secondary;
            border-radius: 4px;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 130px auto;
            width: 100%;
            @media (min-width: $mq-tablet) {
                grid-template-columns: 3fr 1fr;
                grid-template-rows: auto;
            }
            .text-button-wrapper {
                order: 2;
                padding: $gap-large;
                display: flex;
                flex-direction: column;

                @media (min-width: $mq-tablet) {
                    order: 1;
                    padding: calc($gap-xlarge * 1.6);
                }
                .text-wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: $gap-large;
                }
                * {
                    transition-duration: 1s;
                    text-decoration: none;
                    width: 100%;
                }
                #buttonWrapper {
                    display: grid;
                    justify-items: center;
                    align-items: center;
                    grid-auto-flow: row;
                    grid-template-columns: auto;
                    gap: 1rem;

                    @media (min-width: calc($mq-tablet)) {
                        grid-auto-flow: column;
                        justify-content: center;
                    }
                    input {
                        padding: calc($gap-small * 0.6) calc($gap-xlarge * 1);
                        border-radius: 30px;
                        font-size: calc($text-regular * 0.9);
                        font-family: "text-bold";
                        box-sizing: border-box;
                        cursor: pointer;
                        position: relative;
                        z-index: 2;
                        background: transparent;
                        border: 2px solid $primary-light-color;
                        color: $primary-button-color;
                        @media (min-width: $mq-tablet) {
                            font-size: $text-regular;
                            padding: calc($gap-small * 1.1) calc($gap-xlarge * 0.6);
                        }
                    }
                    button {
                        padding: calc($gap-small * 0.7) calc($gap-xlarge * 1.3);
                        width: 100%;
                        @media (min-width: $mq-tablet) {
                            padding: calc($gap-small * 1.3) calc($gap-xlarge * 1.6);
                            font-size: $text-regular;
                        }
                    }
                    .input-hidden {
                        padding: 0;
                        font-size: 0;
                        border-width: 0;
                        height: 0;
                        width: 100%;
                    }

                    .download-pdf {
                        display: grid;
                        grid-auto-flow: column;
                        align-items: center;
                        justify-content: space-evenly;
                        padding: 0;
                        font-size: 0;
                        border: none;
                        width: 100%;

                        button {
                            width: 100%;
                            @media (min-width: $mq-tablet) {
                                width: 100%;
                                font-size: $text-regular;
                            }
                        }
                    }
                    .navigation-button.forward-button {
                        white-space: pre;
                    }
                    .download-pdf.input-hidden {
                        padding: 0;
                        font-size: 0;
                        height: 0;
                        button {
                            padding: 0;
                            font-size: 0;
                            height: 0;
                        }
                    }

                    .spinning-loader-wrapper {
                        position: relative;
                        width: 0px;
                        height: 0px;
                        display: none;
    
                        .spinning-loader {
                            position: absolute;
                            left: 0px;
                            transform: translateY(-50%);
                            width: 3rem;
                            height: 3rem;
                            border: 8px solid #444;
                            border-right-color: transparent;
                            border-radius: 50%;
                            box-shadow: 0 0 25px 2px #bbb;
                            animation: spin 1s linear infinite;
                        }
                        @keyframes spin
                        {
                            from { transform: translateY(-50%) rotate(0deg);   opacity: 0.2; }
                            50%  { transform: translateY(-50%) rotate(180deg); opacity: 1.0; }
                            to   { transform: translateY(-50%) rotate(360deg); opacity: 0.2; }
                        }
                    }
                }

                .email-message-wrapper {
                    height: 2rem;
                    margin-top: 1rem;
                    margin-bottom: -3rem;
                    width: 100%;
                    text-align: center;

                    .email-message.error {
                        color: red;
                    }
                    .email-message.success {
                        color: darkgreen;
                    }
                    
                }
            }
            .image-wrapper {
                order: 1;
                @media (min-width: $mq-tablet) {
                    order: 2;
                }
                position: relative;
                img {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    min-height: auto;
                    height: 180px;
                    max-width: auto;
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    @media (min-width: $mq-tablet) {
                        grid-template-columns: 3fr 1fr;
                        min-height: 110%;
                    }
                    object-fit: contain;
                    vertical-align: bottom;
                }
            }
        }
        .reset-button-wrapper {
            display: flex;
            justify-content: center;
        }
    }
}
