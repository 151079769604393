.footer-wrapper {
    grid-area: footer;
    display:flex;
    align-items: center;
    justify-content: center;
    padding: $gap-regular;
    max-width: 100%;
    height: 80px;
    @media (min-width: $mq-tablet) {
        height: 120px;
    }
   
    .footer-content-wrapper {
        border-top:1px solid $faded-line;
        display:flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        max-width: $content-max-width;
        padding-top: $gap-regular;
        @media (min-width: $mq-tablet) {
            align-items: center;
        }
        .footer-divider {
            display:flex;
            flex-direction: column-reverse;
            align-items: center;
            gap: $gap-small;
            @media (min-width: $mq-tablet) {
                flex-direction: row;
                align-items: center;
                gap: $gap-regular;
            }
            span {
                font-family: "text-bold";
                color: $primary-color;
                cursor: pointer;
            }
        }
    }
}