.progress-bar{
    border-radius:5px;
    width: 100%;
    height:2px;
    background: white;
    .progress {
        background: $primary-color;
        height: 100%;
        max-width: 100%;
        width: 0%;
    }
    .progress-finished {
        width:100%;
        background-color: $category-color-finished;
    }
}