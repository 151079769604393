.navigation-button {
    padding:calc($gap-small * 0.7) calc($gap-xlarge*1.3);    
    border-radius:30px;
    font-size: calc($text-regular * 0.9);
    font-family: 'text-bold';
    box-sizing: border-box;
    border:none;
    cursor: pointer;
    position: relative;
    z-index: 2;
    width: auto;
    @media (min-width: $mq-tablet) {
        font-size: $text-regular;
        padding:calc($gap-small*1.3) calc($gap-xlarge*2.2);
    }
}

.forward-button {
    background-color: $primary-button-color;
    color: $background-color;
}
.back-button {
    background: transparent;
    border:2px solid $primary-light-color;
    color: $primary-button-color;
}

.disabled {
    opacity: 0.6;
    pointer-events: none 
}