.outer-disclaimer-wrapper {
    max-width: 1100px;
    margin:0 auto;
    display:flex;
    flex-direction: column;
    align-items: center;
    padding-top: $gap-xxlarge;
    p{
        font-size: $text-regular;
        line-height: calc($text-regular * 1.5);
        color: $primary-text-color;
        font-family: 'text-regular';
        margin-bottom:$gap-large;
        max-width: 86%;
    
        b {
            font-family:'text-bold';
        }
    }
}