.Question-number-wrapper {
    display:flex;
    flex-direction: column; 
    align-items: center;
    gap:$gap-xlarge;
    text-align: center;
    width: 100%;
    @media (min-width: $mq-tablet) {
        width: 80%;
    }

    .input-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        max-width: 80%;
        gap: $gap-regular;
        .form-check {
            width:40%
        }
    
    input {
        border-radius: 5px;
        border: 0px;
        background: #faf6ff;
        font-size: 20px;
        width: 120px;
        height: 60px;
        outline: none;
        text-align: center;
        padding: 5px;
        box-sizing: border-box;
        color:$primary-color;
        font-family:"text-regular"
    }
    span{
        color:$primary-color;
        font-family:"text-bold";
        font-size: $text-large;
    }
}
}