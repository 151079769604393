.status-wrapper {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 118px;
    gap:15px;
    opacity:0.3;
    text-align: center;
    @media (min-width: $mq-tablet) {
        min-height: 115px;
    }
    .image-text-holder{
        display: flex;
        flex-direction: column;
        gap:$gap-small;width: 100%;
        align-items: center;
    }

    img {
        width:80%;
        max-width:100px;
        max-height: 50px;
        object-fit: contain;
        @media (min-width: $mq-tablet) {
            max-width:150px;
            max-height: 60px;
        }
    }
    span {
        font-family:'text-bold';
        font-size: $text-regular;
        color: $primary-color;
        @media (min-width: $mq-tablet) {
            font-size: $text-large;
        }
    }
    .category-complete {
        span {
            color:$category-color-finished;
        }
    }
    
   
    
}
.status-active {
    opacity:1;
}