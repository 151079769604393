.Question-multiple-choice-wrapper {
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    gap:$gap-xlarge;
    .question-header-wrapper {
         width: 100%;
        @media (min-width: $mq-tablet) {
            width: 80%;
        }
    }
    .input-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 100%;
        align-items: center;
        @media (min-width: $mq-tablet) {
            max-width: 80%;
        }
        
        .form-check {
            width:100%;
            @media (min-width: $mq-tablet) {
                width:46%;
            }
        }
    }
    
    .form-check{
        margin-bottom:$gap-large;
    }
    input[type="checkbox"]{
        display: none;
        margin-bottom:$gap-large;
    }
    label span{
        font-family: 'text-regular';
        font-size: clamp($text-regular, 5vw, $text-large);
        color:$primary-color;
        position: relative;
        line-height: 31px;

       
    }
    label span:before,
    label span:after {
    content: '';
    }
    label span:before {
       background-color: white;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: top;
        border-radius: 4px;
      }
      
      label span:after {
        content:'\2713';
        opacity: 0;
        color: white;
        padding-left: 7px;
        box-sizing: border-box;
        position: absolute;
        left: 0px;
        top: -2px;
        height: 30px;
        width: 30px;
        background-color: $primary-button-color;
        border-radius: 4px;
    
      }
      label input:checked+span:after {
        opacity: 1;
      }

}
