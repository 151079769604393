.questionHolder-wrapper {
    grid-area: main;
    display:flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding:$gap-xxlarge $gap-regular;
    min-height: calc(800px - 500px);
    @media (min-width: $mq-tablet) {
        min-height: calc(800px - 300px);
        padding:$gap-xlarge $gap-large;
        // padding:calc($gap-xlarge * 2) calc($gap-xlarge * 4);
    }


    .questionHolder-content-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: $content-max-width;
        .question-header-wrapper {
            text-align: center;
           
        }
        h1{
            font-size: clamp($text-xlarge, 6vw, $text-xxlarge);
            line-height: clamp(calc($text-xlarge + 0.5rem), 6vw, calc($text-xxlarge + 0.5rem));
            color: $primary-color;
            font-family: 'text-regular';
            max-width: 100%;
            text-align: center;
            margin: 0   auto;
            font-weight: 400;
            max-width: 700px;
            @media (min-width: $mq-tablet) {
                max-width: 80%;
                // padding:calc($gap-xlarge * 2) calc($gap-xlarge * 4);
            }
        }
        p{
            font-size: clamp($text-regular, 4vw, $text-large);
            max-width: 100%;
            color: $primary-color;
            font-family: 'text-regular';
            max-width: 700px;
            margin: 2rem auto;
            text-align: center;
        }
     
    }
}