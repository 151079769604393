.Question-textbox-wrapper {
    padding-top: $gap-large;
    display:flex;
    flex-direction: column;
    gap:$gap-xlarge;
    .Question-text-wrapper {
       .text-content {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: $gap-regular;
       }
        p {
            font-size: $text-large;
            color: $primary-color;
            font-family: 'text-regular';
        }
    }
    .input-wrapper {
        display:flex;
        justify-content: center;
        textarea {
            border-radius: 5px;
            border: 0px;
            background: #faf6ff;
            font-size: 20px;
            width: 100%;
            height: 170px;
            outline: none;
            text-align: left;
            padding: $gap-large $gap-regular;
            box-sizing: border-box;
            color:$primary-color;
            font-family:"text-regular";
            resize: none;
           
            @media (min-width: $mq-tablet) {
                height: 250px;
                width: 75%;
                margin-bottom:$gap-xxlarge;
            }
        }
    }
}