.Question-single-choice-wrapper {
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap:$gap-xlarge;
    @media (min-width: $mq-tablet) {
      max-width: 80%;
    
  }
  .center-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }
  .input-wrapper{
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap:$gap-small;
    max-width: 500px;
    @media (min-width: $mq-tablet) {
      gap:$gap-large;  
    }
    width: 100%;
    

    input[type="radio"]{
        display: none;
    }
   
    .form-check{
      width: 100%;
      @media (min-width: $mq-desktop) {
       width: auto;
    }
      label {
      background: white;
      display:flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 20px 10px;
      height: auto;
      
      border-radius: 10px;
      box-sizing: border-box;
      cursor: pointer;
      font-size: $text-large;
      color: $primary-color;
      font-family: 'text-bold';
      position: relative;
       width: 100%; 
      @media (min-width: $mq-desktop) {
        height: 110px;
        width: 240px;
    }
    }
    label:has(input:checked)  {
      background-color: $primary-button-color;
      color: white;
      position: relative;
      :after {
        content:'\2713';
        font-family: 'text-regular'; 
        position: absolute;
        padding:5px;
        box-sizing: border-box;
        top:-7px;
        right:-7px;
        background-color: $primary-button-color;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        font-size:16px;
      }
    }
   
   
  }
      
     
    }
   
    
  }
  .three-items {
      .input-wrapper{
        max-width: 700px!important;
        justify-content: center;
      
      .form-check{
          label{
            width:100%;
            @media (min-width: $mq-desktop) {
              height: 110px;
              width: 200px!important;
          }

        }
      }
    }
  }
