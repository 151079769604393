@font-face {
    font-family: 'text-bold';
    font-style: normal;
    src: url("../Assets/Fonts/bariol_bold-webfont.woff2") format('woff2');
  }
  
  @font-face {
    font-family: 'text-regular';
    font-style: normal;
    font-weight: 400;
    src: url("../Assets/Fonts/bariol_regular-webfont.woff2") format('woff2');
  }

  @font-face {
    font-family: 'text-light';
    font-style: normal;
    src: url("../Assets/Fonts/bariol_light-webfont.woff2") format('woff2');
  }