.background {
    position: absolute;
    width: 400%;
    height: 100%;
    background-image: url('./../../../public/Icons/wave.svg');
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 140%; 
    left:-10%;
    bottom:-480px;
    z-index: 1;
    @media (min-width: $mq-tablet) {
        bottom:-170px;
        width: 140%;
     }
}
.Start-page-wrapper {
    position: relative;
    // padding:calc($gap-xlarge * 4) calc($gap-xlarge * 4);
    height: auto;
    box-sizing: border-box;
    grid-area: main;
    padding:$gap-large  $gap-regular;
    min-height: 800px;
  
    @media (min-width: $mq-tablet) {
        // padding:calc($gap-xlarge) calc($gap-xlarge * 2);
    }
    .Start-page-content-wrapper {
        display: flex;
        flex-direction: column-reverse;
        @media (min-width: $mq-tablet) {
           flex-direction: row;
        }
       
        .text-wrapper {
            max-width:100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 2;
            
            @media (min-width: $mq-tablet) {
                width: 50%;
                align-items: flex-start;
                justify-content: center ;
            }
            .text-content {
                margin-bottom:$gap-xlarge;
            }

        }
        .image-wrapper {
            max-width:100%;
            margin:0 auto;
            z-index: 2;
            margin-bottom:$gap-xlarge;
            @media (min-width: $mq-tablet) {
                max-width: 50%;
                margin-bottom:$gap-regular;
            }
            .mobile-image { 
                display: block;
                @media (min-width: $mq-tablet) {
                    display: none;
                }
            }
            .desktop-image {
                display: none;
                @media (min-width: $mq-tablet) {
                    display: block;
                }
            }
            img{
                object-fit: contain;
                height: 350px;
                @media (min-width: $mq-tablet) {
                   height:700px;
                }

            }
        }
        
        h1{
            font-size: clamp($text-xlarge, 5vw, calc($text-xxlarge ));
            line-height: $text-xxlarge;;
            color: $primary-color;
            font-family: 'text-regular';
            margin-bottom:$gap-xlarge;
            font-weight: 400;
        }
        p{
            font-size: $text-regular;
            line-height: calc($text-regular * 1.5);
            color: $primary-text-color;
            font-family: 'text-regular';
            margin-bottom:$gap-large;
        }
        b {
            font-size: $text-regular;
            color: $primary-text-color;
            font-family: 'text-bold';
        }
        
    }
    .disclaimer-wrapper {
        position: relative;
        z-index: 2;
        display:flex;
        align-items: center;
        gap:$gap-regular;
        margin-top: $gap-xlarge;
        border-radius:4px;
        padding:$gap-small;
        background-color: $faded-background; 
        width: 100%;
        border:1px solid $primary-button-color;
        margin-top:calc($gap-xxlarge * 2);  
        margin-bottom:calc($gap-xxlarge); 
        @media (min-width: $mq-tablet) {
            padding: $gap-regular $gap-xlarge;
         }         
        span {
            font-size: $text-regular;
            color: $primary-button-color;
           
            
        }
    }
}