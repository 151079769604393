.main-frame {
    overflow: hidden;
    padding:0;
    display: grid;
    max-width: 1150px;
    min-height: 500px;
    margin:0 auto;
    margin-top:20px;
    background-color: $background-color;
    grid-template-columns: 100%;
    grid-auto-rows: auto auto auto;
    grid-template-areas: 
    'header'
    'main'
    'footer';
    border-radius:5px;
    padding:calc($gap-xlarge) $gap-regular;
    @media (min-width: $mq-tablet) {
        min-height: 800px;
        padding:calc($gap-xlarge) calc($gap-xlarge * 3);
        grid-template-rows: auto auto auto;
    }
    }
    