.gtk-wrapper {
    padding-top:calc($gap-xxlarge * 1.5);
    padding-left: $gap-xlarge;
    padding-right: $gap-small;
    max-width: 1100px;
    margin:0 auto;
    display:grid;
    grid-template-columns:(80px 1fr);
    grid-gap:$gap-large;

    @media (min-width: $mq-tablet) {
        grid-template-columns:(140px 1fr);
    }
        .image-wrapper {
            height: 80px;
            width: 80px;
            @media (min-width: $mq-tablet) {
                height: 140px;
                width: 140px
            }
            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
        h2 {
            font-size: $text-large;
            line-height: $text-large;
            color: $primary-color;
            font-family: 'text-bold';
            margin-bottom:$gap-regular;
            font-weight: 400;
        }
    p{
        font-size: $text-regular;
        line-height: calc($text-regular * 1.5);
        color: $primary-text-color;
        font-family: 'text-regular';
        margin-bottom:$gap-large;
        max-width: 86%;
    
        b {
            font-family:'text-bold';
        }
    }
}