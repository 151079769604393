.resultpage-wrapper{
    height: auto;
    box-sizing: border-box;
    grid-area: main;
    padding: $gap-xlarge  $gap-small;
    padding-bottom:0;
    min-height: 800px;
    position: relative;
    @media (min-width: $mq-tablet) {
        padding:calc($gap-xlarge) calc($gap-xlarge);
    }
    
    .summary-box    {
        background-color: $background-color-secondary;
        border-radius:4px;
        width: 100%;
        color:$primary-color;
        padding: calc($gap-xlarge * 1.3) $gap-xlarge;
        margin-bottom: calc($gap-xxlarge*1.5);
        position: relative;
    }
    h1{
        font-size: clamp($text-large, 7vw, calc($text-xxlarge * 0.7));
        line-height: clamp(calc($text-xlarge + 0.5rem), 6vw, calc($text-xxlarge + 0.5rem));
        color: $primary-color;
        font-family: 'text-regular';
        margin-bottom: calc($gap-xlarge);
        font-weight: 400;
    }
    h2 {
        font-size: clamp($text-large, 8vw, calc($text-xlarge * 0.9)); 
        margin-bottom: $gap-regular;
        font-weight: 100;
        
        @media (min-width: $mq-tablet) {
            max-width: 60%;
        }
    }
    ul {
        padding-left:$gap-large;
        max-width: 100%;
        @media (min-width: $mq-tablet) {
            max-width: 60%; 
        }
       
    }
    p, li{
        font-size: clamp($text-regular, 6vw, calc($text-large));
        margin-bottom: $gap-small; 
    }
    p{
        line-height: clamp(calc($text-regular * 1.8), 6vw, calc($text-large * 1.5));
    }

    li span, p span {
        font-family: 'text-bold';
    }
    img{
        
        position: relative;
        width: 80%;
        object-fit:contain;
        margin:0 auto;
        @media (min-width: $mq-tablet) {
            width: 50%;
            position: absolute;
            top: auto;
            width: auto;
            max-width: 60%;
            bottom:0px;
            height: 500px;
            right:0px;
        }
    }
    .content-wrapper {
        display:flex;
        flex-direction: column;
        position: relative;
    }
}