.header-wrapper {
    grid-area: header;
    display:flex;
    align-items: center;
    justify-content: center;
    padding:0;
    // height: 150px;
    @media (min-width: $mq-tablet) {
    
        height: 150px;
    }
  
    .header-content-wrapper {
        display:flex;
        align-items: center;
        justify-content: space-between;
        gap:calc($gap-small*2);
        width: 100%;
        max-width: $content-max-width;
    }
}