.textarea-wrapper{
    width: 100%;
    @media (min-width: $mq-tablet) {
        width: 80%;
    }
    textarea {
        border-radius: 5px;
        border: 0px;
        background: #faf6ff;
        font-size: 20px;
        width: 100%;
        height: 170px;
        outline: none;
        text-align: left;
        padding: $gap-large $gap-regular;
        box-sizing: border-box;
        color:$primary-color;
        font-family:"text-regular";
        resize: none;
        @media (min-width: $mq-tablet) {
            height: 300px;
        }
    }
    h2 {
        margin-bottom:$gap-large;
        color: $primary-color;
        font-family: 'text-bold';
        font-size: calc($text-large * 1.1);
        font-weight: 700;

    }
}
.follow-up-active {
    display: block;
}
.follow-up-inactive{
    display: none;
}